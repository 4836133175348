<template>
  <b-table
    scrollable
    class="table is-fullwidth dispensation-table-listing"
    detail-key="id"
    ref="dispensation-table"
    :data="dispensations"
    :detailed="displayColumn('Itens')"
    :opened-detailed="
      dispensations
        .filter(dispensation => dispensation.itens.some(item => item.id))
        .map(dispensation => dispensation.id)
    "
    :show-detail-icon="false"
  >
    <b-table-column
      centered
      field="comprador.nome"
      label="Comprador"
      :sortable="isNotBuyer"
      v-slot="props"
      :visible="displayColumn('Comprador')"
    >
      <div class="dispensation-column">
        {{ props.row.comprador.nome }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="numero_processo"
      label="Processo"
      v-slot="props"
      :visible="displayColumn('Nº Processo')"
    >
      <div class="dispensation-column">
        {{ props.row.numero_processo || 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="numero_dispensa"
      label="Número"
      v-slot="props"
      :visible="displayColumn('Número')"
    >
      <div class="dispensation-column">
        <router-link
          v-if="props.row.situacao.id > 1"
          :to="{ name: 'Dispensa', params: { id: props.row.id } }"
        >
          {{ props.row.numero_dispensa }}
        </router-link>
        <p v-else>{{ props.row.numero_dispensa || 'N/A' }}</p>
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="objeto"
      label="Objeto"
      v-slot="props"
      :visible="displayColumn('Objeto')"
    >
      <div class="dispensation-column">
        {{ props.row.objeto || 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="data_publicacao"
      label="Publicação"
      v-slot="props"
      :visible="displayColumn('Publicação')"
    >
      <div class="dispensation-column">
        {{ props.row.data_publicacao ? formatDate(props.row.data_publicacao) : 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="data_abertura"
      label="Abertura"
      v-slot="props"
      :visible="displayColumn('Abertura')"
    >
      <div class="dispensation-column">
        {{ props.row.data_abertura ? formatDate(props.row.data_abertura) : 'N/A' }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="tipo_negociacao"
      label="Negociação"
      v-slot="props"
      :visible="displayColumn('Negociação')"
    >
      <div class="dispensation-column">
        {{ props.row.tipo_negociacao.nome }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="tipo_participacao"
      label="Participação"
      v-slot="props"
      :visible="displayColumn('Tipo de Participação')"
    >
      <div class="dispensation-column">
        {{ formatParticipation(props.row.tipo_participacao) }}
      </div>
    </b-table-column>

    <b-table-column
      centered
      sortable
      field="situacao.nome"
      label="Situação"
      v-slot="props"
      :visible="displayColumn('Situação')"
    >
      <div class="dispensation-column">
        {{
          props.row.situacao.nome === 'Encerrada com Vencedor'
            ? 'Encerrada'
            : props.row.situacao.nome
        }}
      </div>
    </b-table-column>

    <template v-slot:detail="props">
      <div v-if="props.row.itens.some(element => element.id)" class="item-container">
        <div
          class="box item-content"
          v-for="item in props.row.itens"
          :key="item.identificador"
          :ref="'item-container'"
        >
          <div><strong>ID: </strong>{{ item.identificador }}</div>
          <div :class="{ 'elipsis-description': !expandedDescription }" :ref="'description'">
            <strong>Descrição: </strong>{{ item.descricao }}
          </div>
          <div v-if="item.valorUnitario">
            <strong>Valor Unitário: </strong>{{ item.valorUnitario | formatValue }}
          </div>
          <div v-if="item.valorTotal">
            <strong>Valor Total: </strong>{{ item.valorTotal | formatValue }}
          </div>
        </div>
      </div>
    </template>
  </b-table>
</template>

<script>
import { mapGetters } from 'vuex';

import { formatarData as formatDate, formatarValor as formatValue } from '@/utils/format';

export default {
  name: 'DispensationTable',
  props: {
    columns: { type: Array, default: () => [] },
    expandedDescription: { type: Boolean, default: false },
  },
  data() {
    return {
      formatDate,
    };
  },
  filters: {
    formatValue,
  },
  computed: {
    ...mapGetters({
      dispensations: 'obterDispensas',
      function: 'getFunction',
    }),
    isNotBuyer() {
      return ['AUTORIDADE', 'PREGOEIRO', 'COMPRADOR'].indexOf(this.function) === -1;
    },
  },
  methods: {
    displayColumn(column) {
      return this.columns.some(element => element.name === column && element.display);
    },
    formatParticipation(participation) {
      if (!participation) return 'N/A';

      return {
        AMPLA: 'Participação Ampla',
        EXCLUSIVA: 'Participação Exclusiva de ME/EPP/MEI/Cooperativa Preferência',
      }[participation];
    },
  },
};
</script>

<style lang="scss" scoped>
.dispensation-table-listing {
  font-size: 0.88rem;
}

.elipsis-description {
  max-width: 50ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty-panel {
  justify-content: center !important;
}

.item-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
}

.item-content {
  background-color: #ededed;
  margin: 1rem 0.5rem;
  min-width: 300px;
  text-align: justify;
}
</style>

<style>
.dispensation-table-listing > div > table > thead > tr {
  white-space: nowrap;
}

.dispensation-table-listing > div > table > tbody > tr > td > .detail-container {
  padding: unset !important;
}
</style>

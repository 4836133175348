<template>
  <div class="box panel-container">
    <div class="columns">
      <div class="column is-11">
        <b-field grouped group-multiline>
          <div class="control options" v-for="(column, index) in columns" :key="index">
            <b-checkbox v-model="column.display">
              {{ column.name }}
            </b-checkbox>
          </div>

          <div
            v-if="
              columns.some(column => column.name === 'Itens' && column.display) &&
                displayExpandedDescription
            "
            class="report-options-switch options"
          >
            <b-switch :rounded="false" @input="value => $emit('updateExpanded', value)"
              >Descrições Expandidas</b-switch
            >
          </div>
        </b-field>
      </div>

      <div class="column is-1 is-flex button-container">
        <b-button
          class="print-button"
          icon-left="printer"
          type="is-primary"
          @click.stop.prevent="printOut()"
          >Imprimir
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AuctionOptions',
  props: {
    columns: { type: Array, default: () => [] },
    displayExpandedDescription: { type: Boolean, default: true },
  },
  methods: {
    printOut() {
      this.$parent.printOut();
    },
  },
};
</script>

<style lang="scss" scoped>
.button-container {
  align-items: center;
  justify-content: center;
}

.options {
  margin: 0.5rem;
}

.panel-container {
  margin-top: 1rem;
}

.print-button {
  margin-right: 1.5rem;
}
</style>

<template>
  <div class="dispensation-listing-container">
    <b-loading is-full-page :active="loading" can-cancel></b-loading>

    <AppTitle expand-title titulo="Listagem de Dispensas" />

    <section>
      <div class="container is-fluid dispensation-filters-container">
        <Dispensation-Filters />
      </div>
    </section>

    <section>
      <div class="container is-fluid dispensation-options-container">
        <Report-Options
          :columns.sync="columns"
          :display-expanded-description="displayExpandedDescription"
          @update-expanded="value => (expandedDescription = value)"
        />
      </div>
    </section>

    <section>
      <div class="container is-fluid dispensation-table-container">
        <div v-if="!dispensations.length" class="panel">
          <div class="panel-block empty-panel">
            <section v-if="!dispensations.length" class="section">
              <div class="content has-text-grey has-text-centered">
                <p>Nenhuma Dispensa.</p>
              </div>
            </section>
          </div>
        </div>

        <div v-if="dispensations.length" class="panel">
          <div class="box panel-block">
            <Dispensation-Table
              ref="dispensation-table"
              :columns="columns"
              :expanded-description="expandedDescription"
            />
          </div>
        </div>
      </div>

      <div class="container is-fluid pagination-container">
        <AppPagination
          :data-filters="filters"
          :has-margin="false"
          :total="count"
          @change-page="
            page => {
              (filters.page = page), filtrarDispensas({ page });
            }
          "
          @change-per-page="
            perPage => {
              (filters.perPage = perPage), alterarPorPaginaDispensas(perPage);
            }
          "
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Printd } from 'printd';

import AppPagination from '@/components/AppPagination';
import AppTitle from '@/components/AppTitulo';
import DispensationTable from '@/views/Reports/DispensationTable';
import DispensationFilters from '@/views/Dispensa/DispensaLista/DispensaFiltros';
import ReportOptions from '@/views/Reports/ReportOptions';

export default {
  name: 'DispensationListing',
  components: {
    AppPagination,
    AppTitle,
    DispensationTable,
    DispensationFilters,
    ReportOptions,
  },
  data() {
    const columns = [
      { name: 'Comprador', display: false },
      { name: 'Processo', display: false },
      { name: 'Número', display: true },
      { name: 'Objeto', display: true },
      { name: 'Publicação', display: false },
      { name: 'Abertura', display: true },
      { name: 'Negociação', display: false },
      { name: 'Tipo de Participação', display: false },
      { name: 'Situação', display: true },
      { name: 'Itens', display: false },
    ];
    const filters = {
      page: 1,
      perPage: 20,
    };

    return {
      columns,
      filters,
      displayExpandedDescription: false,
      expandedDescription: false,
    };
  },
  beforeRouteLeave(to, from, next) {
    this.limparFiltrosDispensas();
    return next();
  },
  watch: {
    columns: {
      deep: true,
      handler() {
        const itemActive = this.columns.some(column => column.name === 'Itens' && column.display);
        if (itemActive) {
          this.verifyExpandedDescription();
        }
      },
    },
  },
  computed: {
    ...mapState('companies', ['company']),
    ...mapGetters({
      count: 'obterTotalDispensas',
      dispensations: 'obterDispensas',
      loading: 'obterCarregandoDispensas',
    }),
  },
  methods: {
    ...mapActions(['alterarPorPaginaDispensas', 'filtrarDispensas', 'limparFiltrosDispensas']),
    checkSeller() {
      if (this.company.seller) {
        this.columns[0].display = true;
      }
    },
    printOut() {
      const impression = new Printd();
      const table = this.$refs['dispensation-table'].$el.children[1].children[0];
      impression.print(table, [
        'a { color: black; text-decoration: none; }',
        'table { width: 100%; }',
        'td { text-align: center; }',
        'table, th, td { border: 1px solid black; border-collapse: collapse; font-size: 14px; }',
        '.dispensation-column { margin: 0 2px; }',
        this.expandedDescription
          ? ''
          : '.elipsis-description { max-width: 50ch; overflow: hidden; text-overflow: ellipsis; white-space: nowrap;}',
        '.item-container { display: flex; flex-wrap: wrap; margin-bottom: 1rem }',
        '.item-content { margin: 0.5rem 0.5rem; min-width: 300px; text-align: justify; }',
      ]);
    },
    verifyExpandedDescription() {
      setTimeout(() => {
        const table = this.$refs['auction-table'];
        if (table) {
          const itemBox = table.$refs.description;
          const itemContainer = table.$refs['item-container'];

          this.displayExpandedDescription = itemBox.some(
            (box, index) => box.scrollWidth >= itemContainer[index].scrollWidth,
          );
        }
      }, 1);
    },
  },
  async created() {
    await this.filtrarDispensas();
  },
  mounted() {
    this.checkSeller();
  },
};
</script>

<style lang="scss" scoped>
.dispensation-filters-container {
  margin-top: 3rem;
}

.dispensation-options-container {
  margin: 1rem 0;
}

.dispensation-table-container {
  margin-bottom: 2rem;
}

.empty-panel {
  justify-content: center !important;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 3rem;
}
</style>
